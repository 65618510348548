<template>
  <v-navigation-drawer v-model="drawer" absolute temporary right class="nav">
    <div class="nav__header d-flex justify-space-between align-center">
      <a @click="drawer = false" href="#!"
        ><v-icon large color="black">mdi-close</v-icon></a
      >
      <div class="logo">
        <img
          src="@/assets/logo/ЛОГО_КОНТУР.svg"
          alt=""
          width="120px"
          height="70px"
        />
      </div>
    </div>

    <v-list nav dense class="nav__list">
      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item
          v-for="item in navItems"
          :key="item.id"
          class="nav__item"
          link
          :to="item.link"
          active-class="nav__item--active"
        >
          <v-list-item-title class="nav__title">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <div class="nav__contacts">
        <p>Контакты</p>
        <ul class="nav__links">
          <li>
            <a href="mailto:socialproject2022@mail.ru" target="_blank"
              ><img src="../../assets/35.svg"
            /></a>
          </li>
          <li>
            <a href="https://t.me/+qtJ6uVyUlqAxNzky" target="_blank"
              ><img src="../../assets/37.svg"
            /></a>
          </li>
          <li>
            <a href="https://vk.com/public220092563" target="_blank"
              ><img src="../../assets/36.svg"
            /></a>
          </li>
        </ul>
      </div>

      <div class="nav__supLinks">
        <a href="/files/ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ.pdf" type="file" download="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ.pdf" target="_blank">
          <p class="nav__supLinks__p">Политика Конфиденциальности</p>
          </a>

            <a href="/files/Условия использования.pdf" type="file" download target="_blank">
              <p class="nav__supLinks__p">Условия использования</p>
          </a>

            <a href="/files/Политика_обработки_персональных_данных.pdf" type="file" download target="_blank">
              <p class="nav__supLinks__p">Политика обработки персональных данных</p></a>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      group: null,
      navItems: [
        { id: 0, title: "Профиль", link: "/profile" },
        { id: 1, title: "Главная", link: "/" },
        { id: 2, title: "Лекции", link: "/lectures" },
        { id: 3, title: "Алфавит", link: "/alphabet" },
        { id: 4, title: "Словарь", link: "/dictionary" },
        { id: 5, title: "Цифры", link: "/numbers" },
        { id: 6, title: "Игра", link: "/game" },
      ],
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("toggleDrawer", val);
      },
    },
  },
};
</script>

<style>
.nav__supLinks {
  margin-top: 10px;
  color: #000;
  display: grid;
  gap: 10px;
}

.nav__supLinks a {
}

.nav__supLinks__p {
  margin-bottom: 0px!important;
  color: #000;
  font-size: 22px;
  padding-bottom: 2px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

@media screen and (max-width: 430px) {
  .nav__supLinks {
  /* margin-top: 0px; */
}
    }
</style>
